import React from 'react';
import Button from '../../components/buttons/button';

const AppPsMobile = () => {
  return (
    <div className="min-h-screen flex flex-row items-center justify-center">
      <div>
        <h2 className="text-5xl text-center text-grey-dark font-hairline font-heading">
          Patronscan App
        </h2>
        <div className="flex flex-col justify-center items-center">
          <Button
            link="itms-services:/?action=download-manifest&url=https://secure.servalldatasystems.com/hotscan/scanner/90006/scanner.plist"
            internal={false}
            className="mx-auto w-full"
            blue
            text="Install for iOS"
          />
        </div>
      </div>
    </div>
  );
};

export default AppPsMobile;
